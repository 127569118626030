.message {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.main-url{
    font-size: xx-small;
}

.messageTop {
    display: flex;
    min-height: 38px;
}

.messageImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    /* margin-top: 15px; */
}

.messageText {
    padding: 10px;
    border-radius: 20px;
    background-color: #1877f2;
    color: white;
    max-width: 300px;
    text-wrap: wrap;
    word-wrap: break-word;
}

.messageBottom {
    min-height: 17px;
    font-size: 11px;
}

.message.own {
    align-items: flex-end;
}

.message.own .messageText {
    background-color: rgb(245, 241, 241);
    color: black;
}

@media only screen and (max-width: 600px) {
    .messageText {
        padding: 4px;
    }

    .message.own {
        align-items: normal;
    }

    .message {
        margin-top: 5px;
    }

}