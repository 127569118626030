.message-container {
    display: flex;
    flex-grow: 1;
}

.chat-box {
    padding: 0 15%;
    height: 86vh;
    overflow: auto;
}


.chat-box::-webkit-scrollbar {
    width: 10px;
}

/* Stylize the scrollbar track */
.chat-box::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }
  
  /* Stylize the scrollbar handle or thumb */
  .chat-box::-webkit-scrollbar-thumb {
    background-color: #929191; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Round the corners of the scrollbar thumb */
  }
  
  /* Stylize the scrollbar track when the handle is being hovered */
  .chat-box::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* Set the hover color of the scrollbar thumb */
  }
  
  /* Stylize the scrollbar track (background area) */
  .chat-box::-webkit-scrollbar-track {
    background-color: white; /* Set the color of the scrollbar track */
  }
  
  /* Stylize the scrollbar corner (where vertical and horizontal scrollbars meet) */
  .chat-box::-webkit-scrollbar-corner {
    background-color: #333; /* Set the color of the scrollbar corner */
  }

.send-message {
    padding: 5px;
    width: 90%;
    border-radius: 15px;
    resize: none;
}

.send-message:focus{
    outline: none !important;
    border: 1px solid rgb(168, 168, 168);
    box-shadow: 0 0 5px #4e4e4e;
}

.send-icon {
    border: 0px;
    border-radius: 50%;
    min-width: 50px;
    height: 50px;
}

.send-icon:hover {
    background-color: #d3d3d3;
}

.send-icon i{
    scale: 120%;
    padding-right: 1.2px;
}

.image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 50px;
    height: 50px;
    /* background-color: #EFEFEF; */
    border-radius: 50%;
}

.image-label:hover{
    scale: 110%;
    /* background-color: #d3d3d3; */
}

.name {
    font-size: 14px;
}

.transition{
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, scale 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media only screen and (max-width: 600px) {
    .send-icon {
        margin: -27px 29%;
    }
}

.rotating-container {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}