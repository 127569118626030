.chats-container {
    display: flex;
    flex-grow: 1;
}

.left-box {
    height: 100vh;
    max-width: 300px;
    background-color: #505050;
    overflow: auto;
}

.left-box::-webkit-scrollbar {
    width: 10px;
}

/* Stylize the scrollbar track */
.left-box::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }
  
  /* Stylize the scrollbar handle or thumb */
  .left-box::-webkit-scrollbar-thumb {
    background-color: #929191; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Round the corners of the scrollbar thumb */
  }
  
  /* Stylize the scrollbar track when the handle is being hovered */
  .left-box::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* Set the hover color of the scrollbar thumb */
  }
  
  /* Stylize the scrollbar track (background area) */
  .left-box::-webkit-scrollbar-track {
    background-color: #444; /* Set the color of the scrollbar track */
  }
  
  /* Stylize the scrollbar corner (where vertical and horizontal scrollbars meet) */
  .left-box::-webkit-scrollbar-corner {
    background-color: #333; /* Set the color of the scrollbar corner */
  }

.contacts-image {
    padding: 0px 5px;
    width: 50%;
    height: 50%;
    cursor: pointer;
}

.add-button {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-radius: 2px;
    background-color: grey;
}

.cursor-pointer{
    cursor: pointer;
}

