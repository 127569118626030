.box {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.logo {
    color: white;
    font-family: monospace;
    font-size: 36px;
    margin-left: 10%;
}

.error {
    color: red;
}

.left-side {
    background-image: url("https://res.cloudinary.com/dluwizg51/image/upload/v1658043725/7175572_grz3jf.webp");
    height: 100%;
    flex: 50%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.right-side {
    flex: 50%;
}

.back {
    margin: 25px;
    color: #8692A6;
    display: flex;
    font-size: 18px;
}

.icon {
    margin: 5px;
}

.container-login {
    margin: 10% 20%;
}

.loginText {
    font-family: monospace;
}

.text {
    font-size: 14pt;
}

.loginForm label {
    color: darkslategray;
}

.loginForm input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.loginForm input[type='submit'] {
    background-color: #2C73EB;
    color: white;
}

@media only screen and (max-width: 600px) {
    .box {
        display: unset;
    }

    .left-side {
        background-position: unset;
    }

    .text {
        font-size: 10pt;
    }
}