hr{
    margin: 5px;
    color: white;
}
 
.main-url{
    margin-bottom: 6px;
    font-size: xx-small;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.badge-light{
    background-color: #28a745;
    color: #ffffff;
    border-radius: 50px;
}

.badge-conversation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    left: 45px;
    top: 10px;
}

.badge-place-holder{
    height: 0px;
    right: -29px;
    top: -4px;
    position: relative;
    justify-content: start;
    display: flex;

}

.text-data{
    overflow: hidden;
}

.title-company{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.icon-text{
    min-width: 40px;
    height: 40px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    border-radius: 50%;
    background-color: white;
  }

  .show-all:hover{
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,  white-space 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,  white-space 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    white-space: inherit;
  }

  .chat-item{
    border-radius: 10px;
    margin: 5px;
  }
  
  .chat-item:hover{
    background-color: rgb(255 255 255 / 15%);
  }

  .info-chat{
    padding-top: 5px;
  }

