.find-box {
    width: 40%;
}

.users-box {
    overflow: auto;
}

.users-box::-webkit-scrollbar {
    width: 1px;
}

.user-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid grey;
    object-fit: cover;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .find-box {
        width: 90%;
    }
}